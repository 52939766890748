@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.cross {
  position: relative;
  font-weight: 500;
}
.cancelButton,
.messageFrameHeader {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cancelButton {
  border-radius: var(--br-9xs);
  background-color: var(--color-orange);
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  justify-content: center;
}
.cancelButton:hover,
.messageButton:hover {
  background-color: var(--color-peru);
}
.messageFrameHeader {
  align-self: stretch;
  justify-content: flex-end;
}
.headerVarLabel,
.messageHeaderFrame {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerVarLabel {
  flex: 1;
  position: relative;
  height: 31px;
}
.messageHeaderFrame {
  align-self: stretch;
  height: 62px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
  box-sizing: border-box;
  font-size: var(--font-size-16xl);
  color: var(--color-orange);
}
.messageVarLabel {
  flex: 1;
  position: relative;
}
.messageBodyFrame,
.messageVarButton {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.messageBodyFrame {
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
  color: var(--color-black);
}
.messageVarButton {
  position: relative;
  font-weight: 500;
}
.messageButton {
  flex: 1;
  border-radius: var(--br-9xs);
  background-color: var(--color-orange);
  flex-direction: row;
  padding: var(--padding-5xs) 0;
}
.footerFrame,
.messageButton,
.messageFrame {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerFrame {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-5xs);
}
.messageFrame {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border-radius: var(--br-9xs);
  background-color: var(--primary-contrast);
  width: 550px;
  height: 350px;
  flex-direction: column;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  opacity: 0;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--primary-contrast);
  font-family: var(--font-roboto);
}
.messageFrame.animate {
  animation: 1s ease 0s 1 normal forwards fade-in;
}
@media screen and (max-width: 960px) {
  .messageFrame {
    width: 100%;
  }
}
