@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.cross {
  position: relative;
  font-weight: 500;
}
.cancelButton,
.priceFrameHeader {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cancelButton {
  border-radius: var(--br-9xs);
  background-color: var(--color-orange);
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  justify-content: center;
}
.cancelButton:hover,
.orderbutton:hover {
  background-color: var(--color-peru);
}
.priceFrameHeader {
  align-self: stretch;
  justify-content: flex-end;
  color: var(--primary-contrast);
}
.filenameFrame,
.filenameLabelFrame,
.filenameVarLabel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.filenameVarLabel {
  flex: 1;
  position: relative;
  height: 31px;
}
.filenameFrame,
.filenameLabelFrame {
  border-radius: var(--br-9xs);
  overflow: hidden;
  flex-direction: row;
}
.filenameLabelFrame {
  flex: 1;
  background-color: var(--color-lightgray);
  padding: var(--padding-5xs);
  text-align: center;
}
.filenameFrame {
  align-self: stretch;
  gap: var(--gap-3xs);
  text-align: right;
}
.weightLabel {
  position: relative;
}
.weightFrame {
  flex: 1;
  border-radius: var(--br-9xs);
  background-color: var(--color-lightgray);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.volumeVarLabel {
  position: relative;
  font-weight: 500;
  text-align: left;
}
.volumeFrame {
  flex: 1;
  border-radius: var(--br-9xs);
  background-color: var(--color-lightgray);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  text-align: center;
}
.weightvolumeFrame {
  text-align: left;
}
.dimensionsFrame,
.timeFrame,
.weightvolumeFrame {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.dimensionsFrame {
  border-radius: var(--br-9xs);
  background-color: var(--color-lightgray);
  padding: var(--padding-5xs);
  justify-content: flex-start;
}
.lineSeparator {
  align-self: stretch;
  position: relative;
  border-top: 3px solid var(--color-black);
  box-sizing: border-box;
  height: 3px;
}
.priceLabel {
  flex: 1;
  position: relative;
  font-weight: 500;
  justify-content: center;
  height: 31px;
}
.priceFrame,
.priceLabel,
.priceVarLabel {
  display: flex;
  align-items: center;
}
.priceVarLabel {
  flex: 1;
  position: relative;
  font-size: var(--font-size-16xl);
  color: var(--color-orange);
  text-align: left;
  height: 31px;
}
.priceFrame {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
  justify-content: center;
  gap: var(--gap-3xs);
}
.emailLineedit {
  border: 0;
  background-color: transparent;
  flex: 1;
}
.emailFrame {
  min-height: 80px;
  border-radius: var(--br-9xs);
  background-color: var(--color-lightgray);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-5xs);
  gap: var(--gap-3xs);
}
.confirmTermsFrame,
.emailFrame,
.orderButtonLabel {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmTermsFrame {
  background-color: transparent;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-xl);
  display: inline-flex;
}
.termsConfirmLabel{
  width: 180px;
}
.orderButtonLabel {
  flex: 1;
  position: relative;
  font-weight: 500;
}
.orderbutton {
  flex: 1;
  border-radius: var(--br-9xs);
  background-color: var(--color-orange);
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--primary-contrast);
}
.orderApproveFrame,
.orderFrame,
.orderbutton {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.hintMessage{
  height: auto;
  color: red;
  font-size: 15px;
  padding: var(--padding-8xs);
  margin: 0;
}
.orderApproveFrame {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  /* gap: 40px; */
  text-align: left;
  font-size: 15px;
}
.orderFrame {
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--primary-contrast);
  width: 500px;
  height: 650px;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-xl);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-3xs);
  opacity: 0;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
.orderFrame.animate {
  animation: 1s ease 0s 1 normal forwards fade-in;
}
@media screen and (max-width: 520px) {
  .orderFrame {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .orderFrame {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: center;
    justify-content: center;
  }
}
