@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.agreementHeaderEng,
.agreementHeaderEngFrame {
  display: flex;
  align-items: center;
  justify-content: center;
}
.agreementHeaderEng {
  flex: 1;
  position: relative;
  height: auto;
}
.agreementHeaderEngFrame {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
}
.iHereinafterReferred {
  margin-block-start: 0;
  margin-block-end: 5px;
}
.iCanRevoke {
  margin: 0;
}
.ageementEngFrameContainer {
  flex: 1;
  position: relative;
}
.agreementEngFrame {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-mid);
  color: var(--color-black);
}
.li {
  margin-bottom: 0;
}
.d1 {
  margin: 0;
  padding-left: 23px;
}
.agreementFrame,
.agreementRusFrame,
.userTerms {
  display: flex;
  align-items: center;
  justify-content: center;
}
.agreementRusFrame {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
  text-align: left;
  font-size: var(--font-size-mid);
  color: var(--color-black);
  font-family: var(--font-inherit);
}
.agreementFrame,
.userTerms {
  flex-direction: column;
}
.agreementFrame {
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-9xs);
  background-color: var(--primary-contrast);
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-xl);
  gap: var(--gap-3xs);
  opacity: 0;
}
.agreementFrame.animate {
  animation: 1s ease 0s 1 normal forwards fade-in;
}
.userTerms {
  position: relative;
  width: 100%;
  height: auto;
  padding: var(--padding-11xl);
  box-sizing: border-box;
  background-image: url(/public/user-terms@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 25px;
  color: var(--color-orange);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 960px) {
  .agreementFrame {
    align-self: stretch;
    width: auto;
  }
}
