@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhai:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto: Roboto;
  --font-montserrat: "Montserrat";
  --font-baloo-bhai: "Baloo Bhai";

  /* font sizes */
  --font-size-mid: 17px;
  --font-size-xl: 20px;
  --font-size-16xl: 35px;

  /* Colors */
  --primary-contrast: #fff;
  --color-black: #000;
  --color-orange: #f99a0e;
  --color-peru: #a57630;
  --color-lightgray: #d7cbd8;

  /* Gaps */
  --gap-3xs: 10px;

  /* Paddings */
  --padding-11xl: 30px;
  --padding-3xs: 10px;
  --padding-8xs: 5px;
  --padding-xl: 20px;
  --padding-5xs: 8px;

  /* border radiuses */
  --br-9xs: 4px;
}
