.languageselector {
  align-self: stretch;
  flex: 1;
}
.languageFrame {
  height: 60px;
  align-self: stretch;
  width: 100px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-8xs);
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
.pageHeaderFrameChild {
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
}
.arcoboLogoIcon {
  position: relative;
  width: 318.05px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
}
.pageHeaderFrame {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: flex-start;
}
.mainText {
  align-self: stretch;
  position: relative;
  font-size: 60px;
  /* font-family: var(--font-montserrat), sans-serif;
  font-weight: 700; */
}
.mainTextFrame {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
}
.dropzone {
  width: 100%;
  height: 100%;
  min-height: 120px;
  border: 2px dashed #888888;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555555;
  font-size: 14px;
}
.dropzoneContent {
  font-size: 20px;
  color: #555353;
  text-align: center;
}
.dropzoneText {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}
.dropFilesHere,
.dropzoneField {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropFilesHere {
  position: relative;
  height: 63px;
}
.dropzoneField {
  align-self: stretch;
  border: 1px dashed var(--color-black);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-8xs);
  text-align: center;
}
.dropzoneText2 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
}
.modelInputFrame {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
}
.qtyFrame{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 100%; */
  /* justify-content: flex-start; */
}
.qtyLabel {
  position: relative;
  font-weight: 500;
  display: inline-flex;
  align-items: flex-end;
  flex-shrink: 0;
  /* margin: auto; */
  padding: 10px;
}
.parametersFrame,
.qtyLabel,
.qtySpinbox {
  align-self: stretch;
}
.qtySpinbox {
  align-self: stretch;
  display: inline-flex;
  min-width: 65px;
}
.parametersFrame {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  text-align: center;
}
.materialselector {
  align-self: stretch;
  flex: 1;
  min-width: 180px;
  margin: auto;
}
.agreementLabel,
.termCheckbox {
  position: relative;
}
.termCheckbox {
  width: auto;
}
.agreementLabel {
  align-self: stretch;
  /* flex: 1; */
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
}
.spacer1 {
  padding: var(--padding-3xs);
  overflow: hidden;
  flex-direction: row;
  width: auto;
  max-width: 300px;
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.agreementFrame,
.calculateButton,
.calculateButtonLabel{
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calculateButtonLabel {
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
}
.agreementFrame,
.calculateButton {
  overflow: hidden;
  flex-direction: row;
}
.calculateButton {
  border-radius: var(--br-9xs);
  background-color: var(--color-orange);
  transition: 0.3;
  text-align: center;
  color: var(--primary-contrast);
}
.calculateButton:hover {
  background-color: var(--color-peru);
}
.agreementFrame {
  padding: var(--padding-3xs);
  gap: var(--gap-3xs);
  height: auto;
}
.messageLabel {
  flex: 1;
  position: relative;
  color: red;
  margin: 0;
  padding: 0;
}
.messageLabelWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 1px 0;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-mid);
}
.parametersInputFrame,
.uploadFrame {
  display: flex;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
}
.parametersInputFrame {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  gap: 1px;
}
.uploadFrame {
  background-color: var(--primary-contrast);
  flex-direction: row;
  gap: var(--gap-3xs);
}
.loadingframe,
.mainFrame,
.uploadFrame {
  align-self: stretch;
  overflow: hidden;
}
.mainFrame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
  border-radius: var(--br-9xs);
  height: auto;
}
.loadingframe {
  height: 100px;
  flex-shrink: 0;
}
.mainSection {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-11xl) var(--padding-11xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  background-image: url(/public/main-section@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 60px;
  color: var(--primary-contrast);
  font-family: var(--font-baloo-bhai);
  min-width: 100%;
  min-height: 100vh;
}
@media screen and (max-width: 960px) {
  .arcoboLogoIcon {
    display: flex;
    width: 200px;
    height: 50px;
  }
  .mainText {
    font-size: 30px;
  }
  .modelInputFrame {
    flex: unset;
    align-self: stretch;
  }
  .parametersFrame {
    flex: 1;
    min-height: 80px;
  }
  .agreementFrame {
    min-height: 80px;
  }
  .parametersInputFrame {
    flex: unset;
    align-self: stretch;
  }
  .uploadFrame {
    flex-direction: column;
  }
  .mainFrame {
    height: auto;
  }
}
@media screen and (max-width: 500px) {
  .calculateButton {
    width: 100% !important;
    min-height: 70px;
  }
  .parametersFrame {
    flex-direction: column;
  }
  .agreementFrame {
    flex-direction: column;
  }
  .qtyFrame{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
}
