@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.whyStlHeader,
.whyStlHeader1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.whyStlHeader1 {
  flex: 1;
  position: relative;
  height: auto;
}
.whyStlHeader {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
}
.theStandardTriangle {
  margin-block-start: 0;
  margin-block-end: 5px;
}
.inConclusionStl {
  margin: 0;
}
.whyStlBodyContainer {
  flex: 1;
  position: relative;
}
.whyStl,
.whyStlBody,
.whyStlFrame {
  display: flex;
  align-items: center;
  justify-content: center;
}
.whyStlBody {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
  text-align: left;
  font-size: var(--font-size-mid);
  color: var(--color-black);
}
.whyStl,
.whyStlFrame {
  flex-direction: column;
}
.whyStlFrame {
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-9xs);
  background-color: var(--primary-contrast);
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-xl);
  gap: var(--gap-3xs);
  opacity: 0;
}
.whyStlFrame.animate {
  animation: 1s ease 0s 1 normal forwards fade-in;
}
.whyStl {
  position: relative;
  width: 100%;
  height: auto;
  padding: var(--padding-11xl);
  box-sizing: border-box;
  background-image: url(/public/user-terms@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--font-size-16xl);
  color: var(--color-orange);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 960px) {
  .whyStlFrame {
    align-self: stretch;
    width: auto;
  }
}
